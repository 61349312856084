<template>
    <div class="shop_main noMenu" style="background: #FFF">
        <div class="shop_order_main">
            <userMenu type="33" />

            <div class="shop_order_list">
                <div class="shop_address_title">
                    <h1 class="title" v-if="type === 1">说明：为保证您的账户安全，需验证原支付密码，通过后可完成支付密码修改操作 </h1>
                    <h1 class="title" v-if="type === 2">说明：为保证您的账户安全，需验证手机验证码，通过后可完成支付密码修改操作 </h1>
                </div>

                <div class="shop_form mt30" v-show="type === 1">
                    <el-form ref="form1" :model="form1" :rules="rules" label-width="94px" size="small">
                        <el-form-item label="原支付密码：" class="mb20" prop="originPayPassword">
                            <el-input v-model="form1.originPayPassword" placeholder="请输入原6位支付密码" class="fullInput" show-password></el-input>
                        </el-form-item>

                        <el-form-item label="新支付密码：" class="mb20" prop="payPassword">
                            <el-input v-model="form1.payPassword" placeholder="请输入6位支付密码" class="fullInput" show-password></el-input>
                        </el-form-item>

                        <el-form-item label="确认密码：" class="mb10" prop="payPasswordSure">
                            <el-input v-model="form1.payPasswordSure" placeholder="请输入6位支付密码" class="fullInput" show-password></el-input>
                        </el-form-item>

                        <el-form-item class="mb24">
                            <a href="javascript:void(0)" class="accountLink" @click="type = 2">忘记原支付密码？</a>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" :loading="submitLoading" @click="submitForm1('form1')">提交修改</el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="shop_form mt30" v-show="type === 2">
                    <el-form ref="form2" :model="form2" :rules="rules" label-width="94px" size="small">
                        <el-form-item label="手机号：" class="mb20">
                            <span class="phone">{{phone.length === 11 ? phone.substr(0, 4) + '****' + phone.substr(7, 4) : phone}}</span>
                        </el-form-item>

                        <el-form-item label="验证码：" class="mb20" prop="code">
                            <input type="password"  style="position: fixed; z-index: -999999; width: 0; height: 0"/>
                            <el-input v-model="form2.code" placeholder="请输入验证码" class="phoneInput ml0"></el-input>
                            <el-button :class="isSendCode ? 'on' : ''" type="primary" plan class="halfInput" :loading="isRequestSend" @click="toSendCode">{{isSendCode ? smsBtnText : '获取验证码'}}</el-button>
                        </el-form-item>

                        <el-form-item label="新支付密码：" class="mb20" prop="payPassword">
                            <input type="password"  style="position: fixed; z-index: -999999; width: 0; height: 0"/>
                            <el-input v-model="form2.payPassword" placeholder="请输入6位支付密码" class="fullInput" show-password></el-input>
                        </el-form-item>

                        <el-form-item label="确认密码：" class="mb10" prop="payPasswordSure2">
                            <el-input v-model="form2.payPasswordSure2" placeholder="请输入6位支付密码" class="fullInput" show-password></el-input>
                        </el-form-item>

                        <el-form-item class="mb24">
                            <a href="javascript:void(0)" class="accountLink" @click="type = 1">原支付密码校验</a>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" :loading="submitLoading" @click="submitForm2('form2')">提交修改</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {getUserInfo, smsSend, postIsPayPassword, smsVerify, postIsPayPasswordReset} from "../../../api/public";
  import md5 from 'js-md5';

  const userMenu = () => import('../../../components/layout/shop/userMenu')
  export default {
    name: "shopAccountPay",
    data () {
      let validatePass1 = (rule, value, callback) => {
        if (value !== this.form1.payPassword) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      let validatePass2 = (rule, value, callback) => {
        if (value !== this.form2.payPassword) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        type: 1,
        form1: {
          originPayPassword: '',
          payPassword: '',
          payPasswordSure: '',
        },
        form2: {
          code: '',
          payPassword: '',
          payPasswordSure2: ''
        },
        rules: {
          code: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ],
          originPayPassword: [
            { required: true, message: '请输入原6位支付密码', trigger: 'blur' },
          ],
          payPassword: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 6, max: 6, message: '请输入6位支付密码', trigger: 'blur' }
          ],
          payPasswordSure: [
            { required: true, message: '请再次输入密码', trigger: 'blur' },
            { validator: validatePass1, trigger: 'blur' }
          ],
          payPasswordSure2: [
            { required: true, message: '请再次输入密码', trigger: 'blur' },
            { validator: validatePass2, trigger: 'blur' }
          ],
        },
        phone: '',
        isRequestSend: false,
        submitLoading: false,
        isSendCode: false,
        smsBtnText: ''
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getUserInfo()
    },
    methods: {
      getUserInfo () {
        getUserInfo().then(res => {
          if (res.code === 0) {
            this.phone = res.data.phone
          }
        })
      },
      toSendCode () {
        if (this.isSendCode) {
          return false
        }
        this.isRequestSend = true
        smsSend({
          data: {
            phone: this.phone,
            type: 6
          }
        }).then(res => {
          this.isRequestSend = false
          if(res.code === 0) {
            this.isSendCode = true
            this.toChangeSmsTime(60)
          }
        }).catch(() => {
          this.isRequestSend = false
        })
      },
      toChangeSmsTime (time) {
        if (time === 0) {
          this.isSendCode = false
        } else {
          this.isSendCode = true
          this.smsBtnText = '重新获取(' + time + 's)'
          let times = time - 1
          let that = this
          setTimeout(() => {
            that.toChangeSmsTime(times)
          }, 1000)
        }
      },
      submitForm1 (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitLoading = true
            postIsPayPassword({
              data: {
                originPayPassword: md5(this.form1.originPayPassword),
                payPassword: md5(this.form1.payPassword),
              }
            }).then(res => {
              this.submitLoading = false
              if (res.code === 0) {
                this.$message({
                  message: '密码修改成功',
                  showClose: true,
                  type: 'success'
                });
                this.$router.go(-1)
              }
            }).catch(() => {
              this.submitLoading = false
            })
          }
        })
      },
      submitForm2 (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitLoading = true
            smsVerify({
              data: {
                phone: this.phone,
                type: 6,
                code: this.form2.code
              }
            }).then(res => {
              if (res.code === 0) {
                this.toChangePassword()
              } else {
                this.submitLoading = false
              }
            }).catch(() => {
              this.submitLoading = false
            })
          }
        })
      },
      toChangePassword () {
        postIsPayPasswordReset({
          data: {
            payPassword: md5(this.form2.payPassword)
          }
        }).then(res => {
          this.submitLoading = false
          if (res.code === 0) {
            this.$message({
              message: '密码修改成功',
              showClose: true,
              type: 'success'
            });
            this.$router.go(-1)
          }
        }).catch(() => {
          this.submitLoading = false
        })
      }
    },
    components: {
      userMenu
    }
  }
</script>
